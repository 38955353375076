import React, { useEffect, useState } from "react";
import logo from "../../assets/KOTHI/TheArtKothi.png"; // Replace with your logo path
import quotesData from "../../assets/quotes.json"; // Import the JSON file

const Loader = () => {
  const [randomQuote, setRandomQuote] = useState("");

  useEffect(() => {
    // Pick a random quote from the JSON file
    const quote = quotesData[Math.floor(Math.random() * quotesData.length)];
    setRandomQuote(quote);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent dark background
        zIndex: 9999, // Ensure it sits above other elements
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "30px",
          borderRadius: "10px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
          textAlign: "center",
          display: "flex",
          maxWidth:"350px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "150px",
            height: "150px",
            borderRadius: "50%",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            marginBottom: "20px",
          }}
        >
          <img
            src={logo}
            alt="Hotel Logo"
            style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "50%" }}
          />
        </div>
        <p
          style={{
            fontSize: "1.2em",
            fontStyle: "italic",
            marginBottom: "10px",
            color: "#b67861",
          }}
        >
          {randomQuote}
        </p>
        <p style={{ fontSize: "1em", color: "#b67861" }}>Loading, please wait...</p>
      </div>
    </div>
  );
};

export default Loader;